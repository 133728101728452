/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "fontsource-josefin-sans/300.css"
import "fontsource-montserrat"
import Header from "./header"
import ImageBar from "./ImageBar"
import Footer from "./footer"
import "./all.scss"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <main>
                <div className="main">
                    <Header />
                    <ImageBar />
                    <div className="container">
                        <div className="main-content">
                            {children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
